import { Box, Flex, Grid, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { Tooltip } from 'chart.js';
import { TaticoRhCOlor } from 'components/Dashboards/Colors';
import { OpenQuestion } from 'components/Dashboards/OpenQuestions';
import openQuestions from 'pages/Questions/Others/QuestionTaticoRH/openQuestions.json';
import questions from 'pages/Questions/Others/QuestionTaticoRH/questions.json';
import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useEvaluationSelectorStore } from 'stores/evaluationSelector';
import { getChartFont } from 'utils/chart';
import Legend from './components/Legend';
import { labels, labelsShort } from './config';
import { TaticoRHData } from './types';

declare module 'chart.js' {
  interface TooltipPositionerMap {
    myCustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}

Tooltip.positioners.myCustomPositioner = function (_elements, eventPosition) {
  return {
    x: eventPosition.x,
    y: eventPosition.y,
    xAlign: 'center',
    yAlign: 'bottom',
  };
};



export default function TaticoRH() {
  const [company] = useEvaluationSelectorStore((state) => state.selectedCompanies);

  const [page, setPage] = useState(0);
  const [data, setData] = useState<TaticoRHData>({
    pages: [],
    evaluators: [],
    consolidated: [],
  });

  useEffect(() => {
    if (!company.id) return;
    // useLoadingStore.setState({ loading: true });
    api
      .post(`/tableresponse/evaluations`, { company_id: company.id, type_evaluation: '14' })
      .then((response) => {
        setData(response.data);
      })
      .catch(() => toast.error('Erro ao buscar dados'))
      .finally(() => {
        // useLoadingStore.setState({ loading: false });
      });
  }, [company?.id]);

  if (!data.pages.length) return null;

  return (
    <Flex w="100%" flexDirection="column" paddingLeft="0.625rem" overflow="auto" style={{ scrollbarWidth: 'thin' }}>
      {data.pages ? (
        <Flex gap="0.3125rem">
          <Tabs w="100%" isFitted colorScheme="yellow">
            <TabList>
              <Tab onClick={() => setPage(0)}>Desenvolvimento de Pessoas</Tab>
              <Tab onClick={() => setPage(1)}>Recrutamento e Seleção</Tab>
              <Tab onClick={() => setPage(2)}>Gestão de Desempenho e Retenção</Tab>
              <Tab onClick={() => setPage(3)}>Cultura e Clima Organizacional</Tab>
              <Tab onClick={() => setPage(5)}>Consolidado</Tab>
              <Tab onClick={() => setPage(4)}>Considerações</Tab>
            </TabList>
          </Tabs>
        </Flex>
      ) : null}

      {data.pages && [0, 1, 2, 3, 5].includes(page) ? <Legend data={data} /> : null}

      {data.pages && [0, 1, 2, 3].includes(page) ? (
        <Flex overflowY={'scroll'} flexDirection="column" width="100%" height="100%" alignItems="center">
          <Grid gridTemplateColumns="repeat(3, 1fr)" gap="1rem" maxW="50rem" justifyItems="center">
            {questions[page].map((question, index) => (
              <Flex key={index} flexDirection="column" alignItems="center" width="12rem">
                <Box width="12rem" height="12rem">
                  <Pie
                    data={{
                      labels,
                      datasets: [
                        {
                          data: data.pages[page][index].likert,
                          backgroundColor: Object.values(TaticoRhCOlor),
                        },
                      ],
                    }}
                    options={{
                      animation: {
                        duration: 0,
                      },
                      responsive: true,
                      indexAxis: 'y',
                      layout: {
                        padding: {
                          top: 0,
                          right: 40,
                          left: 40,
                        },
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                          position: 'bottom',
                        },
                        datalabels: {
                          labels: {
                            value: {
                              formatter: (value: number) => {
                                if (!value) return '';
                                return `${((value * 100) / data.evaluators.length).toFixed(0).replace('.', ',')}%`;
                              },
                              color: 'black',
                              font: () => getChartFont(700, 11),
                              anchor: 'end',
                              align: 'end',
                            },
                            name: {
                              formatter: (value, ctx) => {
                                if (value / data.evaluators.length < 0.1) return '';
                                return labelsShort[ctx.dataIndex];
                              },
                              color: (ctx) => {
                                return ctx.datasetIndex > 2 ? 'black' : 'black';
                              },
                              font: () => getChartFont(800, 11),
                              anchor: 'center',
                              align: 'center',
                            },
                          },
                        },
                      },
                    }}
                  />
                </Box>
                <Text textAlign="center" fontSize="0.85rem" fontWeight="semibold">
                  {question.text}
                </Text>
              </Flex>
            ))}
          </Grid>

          <Flex maxWidth="45.5rem" width="100%" height="28.125rem" flexDirection="column">
            <Text fontWeight="800" align="center" marginTop="1.625rem" fontSize="1.25rem">
              Mapeamento estratégico e tático do RH
            </Text>
            <Text
              as="i"
              fontWeight="400"
              align="center"
              marginTop="0.625rem"
              fontSize="0.875rem"
              maxWidth="700"
              color="gray.500"
            >
              Valores em porcentagem de acordo com a escala Likert, com opções que variam de &apos;discordo
              totalmente&apos; até &apos;concordo totalmente&apos;, indicando as escolhas feitas pelos colaboradores.
            </Text>
          </Flex>
        </Flex>
      ) : null}

      {data.pages && page === 4 ? (
        <Flex
          wrap="wrap"
          gap="1.25rem"
          paddingTop="1.25rem"
          paddingBottom="1.25rem"
          overflowY={'scroll'}
          paddingLeft={'0.1875rem'}
        >
          {openQuestions.map((question, index) => {
            if (page === 4 && index < 3) {
              return (
                <OpenQuestion
                  key={question.question}
                  title={question.text}
                  responses={data.pages[4]?.[index].responses || []}
                />
              );
            } else if (index > 2) {
              return (
                <OpenQuestion
                  width={{ xl: '95%', md: '95%', base: '95%' }}
                  key={question.question}
                  title={question.text}
                  responses={data.pages[4]?.[index].responses || []}
                />
              );
            }
          })}
        </Flex>
      ) : null}

      {data.consolidated && page === 5 ? (
        <Flex overflowY={'scroll'} flexDirection="column" width="100%" height="100%" alignItems="center">
          {data.consolidated.length > 0 ? (
            <>
              <Grid gridTemplateColumns="repeat(3, 1fr)" gap="1rem" maxW="50rem" justifyItems="center">
                {data.consolidated.map((con, index) => {
                  return (
                    <Flex key={index} flexDirection="column" alignItems="center" width="12rem">
                      <Box width="12rem" height="12rem">
                        <Pie
                          data={{
                            labels,
                            datasets: [
                              {
                                data: con.likert,
                                backgroundColor: Object.values(TaticoRhCOlor),
                              },
                            ],
                          }}
                          options={{
                            animation: {
                              duration: 0,
                            },
                            responsive: true,
                            indexAxis: 'y',
                            layout: {
                              padding: {
                                top: 0,
                                right: 40,
                                left: 40,
                              },
                            },
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: false,
                                position: 'bottom',
                              },
                              datalabels: {
                                labels: {
                                  value: {
                                    formatter: (value: number) => {
                                      if (!value) return '';
                                      return `${((value * 100) / data.evaluators.length)
                                        .toFixed(1)
                                        .replace('.', ',')}%`;
                                    },
                                    color: 'black',
                                    font: () => getChartFont(700, 11),
                                    anchor: 'end',
                                    align: 'end',
                                  },
                                  name: {
                                    formatter: (value, ctx) => {
                                      if (value / data.evaluators.length < 0.1) return '';
                                      return labelsShort[ctx.dataIndex];
                                    },
                                    color: (ctx) => {
                                      return ctx.datasetIndex > 2 ? 'black' : 'black';
                                    },
                                    font: () => getChartFont(800, 11),
                                    anchor: 'center',
                                    align: 'center',
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </Box>
                      <Text textAlign="center" fontSize="0.85rem" fontWeight="semibold">
                        {con?.text}
                      </Text>
                    </Flex>
                  );
                })}
              </Grid>

              <Text fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
                Consolidado mapeamento estratégico e tático do RH
              </Text>
              <Text
                as="i"
                fontWeight="400"
                align="center"
                marginTop="0.625rem"
                fontSize="0.875rem"
                maxWidth="700"
                color="gray.500"
              >
                Gráfico consolidado dos principais gaps identificados no Mapeamento estratégico e tático do RH.
                Apresenta as respostas negativas, considerando apenas aqueles que responderam &apos;não concordo nem
                discordo&apos;, &apos;discordo parcialmente&apos; e &apos;discordo totalmente&apos;, com pontuação igual
                ou maior que 50%
              </Text>
            </>
          ) : (
            <Flex marginTop={5} justifyContent="center">
              <Text maxWidth="56.25rem" padding={5} fontWeight={600} margin={1}>
                {`
              No consolidado, somente as opiniões discordantes, representadas por "discordo", "discordo totalmente" ou "não concordo nem discordo", são exibidas. Essas opiniões são consideradas quando a soma alcança ou ultrapassa 50%. Se nada for exibido, significa que nenhuma questão avaliada obteve uma pontuação negativa de 50% ou mais. `}
              </Text>
            </Flex>
          )}
        </Flex>
      ) : null}
    </Flex>
  );
}
